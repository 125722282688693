import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { f, database, storage, auth } from "./config";
import firebase from "firebase";
import db from "./config";
import { GlobalContext } from "./Globalstate";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Register() {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const { refer } = useParams()

    const [referralcode, setreferralcode] = useState("none")

    useEffect(() => {
        console.log(refer)
        setreferralcode(refer)
        window.scrollTo(0, 0)
    }, [])



    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const [{ userdetails, loggedin }, dispatch] = useContext(GlobalContext);


    const [open1, setOpen1] = React.useState(false);
    const [errormess, seterrormess] = useState("")

    const handleClick1 = () => {
        setOpen1(true);
    };
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen1(false);
    };

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        pin: "",
        password: "",
        wallet: "",
    });

    const updateFormData = event => {
        event.preventDefault();
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        console.log(formData)
    }

    const { firstname, lastname, email, password, wallet, pin, username } = formData;

    const notereferral = () => {
        const increment = firebase.firestore.FieldValue.increment(10);
        if (referralcode == "none") {
            console.log("no user to refer")
            return
        } else {
            var washingtonRef = db.collection("users").doc(referralcode);
            washingtonRef.update({
                referrals: firebase.firestore.FieldValue.arrayUnion({
                    firstname: firstname,
                    lastname: lastname,
                    email: email
                }),
                balance: increment,
                referralearn: increment

            });
        }
    }


    const Register = async () => {
        console.log("register")
        // event.preventDefault();
        /////////////////////////////////////////////////
        setOpen(!open);
        if (email != "" && password != "") {

            try {
                let user = await auth
                    .createUserWithEmailAndPassword(email.trim(), password)
                    .then((userobj) => {
                        CreatUserObj(userobj.user, email);
                        notereferral();
                        var actionCodeSettings = {
                            url:
                                "https://megagoldtrades.com/login/?email=" +
                                firebase.auth().currentUser.email,
                        };
                        // navigate("/dashboard");

                        firebase
                            .auth()
                            .currentUser.sendEmailVerification(actionCodeSettings)
                            .then(function () {
                                setOpen(!open);
                                //    navigate("/dashboard");

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Registration was sucessfull',
                                    text: 'Redirecting to dashboard',

                                })
                                navigate("/dashboard")

                            })
                            .catch(function (error) {
                                setOpen(!open);
                                console.log(error);

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Registration was sucessfull',
                                    text: 'Redirecting to dashboard',

                                })
                                navigate("/dashboard")

                                // navigate("/dashboard");
                            });

                    })
                    .catch((error) => {
                        setOpen(!open);
                        // console.log(error.message);
                        // seterror(true);
                        seterrormess(error.message);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.message

                        })
                        // handleClick1();
                    });
            } catch (error) {
                setOpen(!open);
                // seterror(true);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message

                })
                // handleClick1();
            }
        } else {
            console.log("khg");
            setOpen(!open);
            seterrormess("please fill in the required fields");
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'please fill in the required fields!',
                footer: '<a href="">Why do I have this issue?</a>'
            })
            // handleClick1();
        }
    };


    function generateRandomCardNumber() {
        let cardNumber = '';
        for (let i = 0; i < 16; i++) {
          cardNumber += Math.floor(Math.random() * 10);
        }
        return cardNumber;
      }
      
      // Function to generate a random expiration date (MM/YY format)
      function generateRandomExpirationDate() {
        const month = String(Math.floor(Math.random() * 12) + 1).padStart(2, '0');
        const year = String(Math.floor(Math.random() * 10) + 22);
        return `${month}/${year}`;
      }
      
      // Function to generate a random 3-digit CVV
      function generateRandomCVV() {
        return String(Math.floor(Math.random() * 1000)).padStart(3, '0');
      }

    const CreatUserObj = async (userobj, email) => {
        const atmCardDetails = {
            cardNumber: generateRandomCardNumber(),
            expirationDate: generateRandomExpirationDate(),
            cvv: generateRandomCVV()
          };

        let uobj = {
            firstname: firstname,
            lastname: lastname,
            email: email,
            pin: pin,
            kycstate:false,
            kycs:"",
            ATM_CARD_DETAILS: atmCardDetails,
            username: username,
            balance: "0",
            lockedbalance: "0",
            totalearnings: "0",
            withdrawalhistory: [],
            Deposithistory: [],
            Investments: [],
            Verificationid: [],
            verified: false,
            password: password,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            cointypes: [],
            referrals: [],
            referralearn: "0",
            profilepic: "",
            currentSubscription: null,
            // referreduserid: referralcode


            //include proof of payment url
        };

        const userdetail = await setdetails(uobj);

        const userdetail1 = await db
            .collection("users")
            .doc(userobj.uid)
            .set(uobj)
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                } else {
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };


    return (
        <div>
            <header>
                {/* TrustBox script */}
                {/* End TrustBox script */}
                <div className="uk-container">
                    <div className="uk-grid">
                        <div className="uk-width-2-5@l uk-width-1-3@m uk-width-1-4@s">
                            {/* logo header begin */}
                            <div id="header-logo">
                                <a className="uk-logo" href="index.php"><img src="/images/pax.png" alt="stockpile" /></a>
                            </div>
                            {/* logo header end */}
                        </div>
                        <div className="uk-width-3-5@l uk-width-2-3@m uk-width-3-4@s uk-visible@m">
                            <div className="uk-grid">
                                <div className="uk-width-1-2 pr-3">
                                    <div className="idz-mini-nav uk-align-right">
                                        <div id="google_translate_element" />
                                    </div>
                                </div>
                                <div className="uk-width-1-2 phone-number">
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            <hr />
                            {/* Main navigation begin */}
                            <nav className="uk-navbar-container uk-visible@m" data-uk-navbar style={{ zIndex: 980 }} data-uk-sticky="animation: uk-animation-slide-top; cls-active: uk-sticky; cls-inactive: uk-navbar-container; bottom: #offset">
                                <div className="uk-navbar-left">
                                    <ul className="uk-navbar-nav">
                                        <li className="uk-active"> <a href="index.php">Home</a></li>
                                        <li> <a href="#">Company</a>
                                            <div className="uk-navbar-dropdown uk-navbar-dropdown-width-2">
                                                <div className="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                                                    <div>
                                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                                            <li><a href="about.php">About Us</a></li>
                                                            <li><a href="affiliate.php">Marketing</a></li>
                                                            <li><a href="for_marketers">For Marketers</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="cannabis.php">Cannabis</a></li>
                                        <li><a href="faq.php">FAQ</a></li>
                                        <li><a href="contact.php">Contact Us</a></li>
                                        <li><a href="rules.php">Rules</a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <div data-uk-margin>
                                        <Link class="uk-button uk-button-primary" to="/login">Get Started</Link>
                                    </div>
                                </div>
                            </nav>

                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div className="simple-marquee-container">
                    <div className="marquee-sibling">
                        INSIGHTS
                    </div>
                    <div className="marquee">
                        <ul className="marquee-content-items">
                            <li>The intelligent investor should recognize that market panics can create great prices for good companies and good prices for great companies </li>
                        </ul>
                    </div>
                </div>  </section>
            <main>
                <section id="pagetitle-container">
                    <div className="uk-container">
                        <div className="uk-grid">
                            <div className="uk-width-1-1">
                                {/* Breadcrumb begin */}
                                <ul className="uk-breadcrumb uk-margin-top uk-float-right">
                                    <li><a href="index.php">Home</a></li>
                                    <li><a href="register.php">Register</a></li>
                                </ul>
                                {/* Breadcrumb end */}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container custom_cont-1">
                    <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s mt-5">
                        <h4 className="area-title">Register Your Account</h4>
                        <p>Do not Disclose your email &amp; password to any third party.</p>
                    </div>
                    <form id="contactForm" method="POST">
                        <div id="signup_error_messages" />
                        <div id="signup_success_messages" />
                        <input type="hidden" name="_token" />
                        <div className="row">

                            <div className="uk-margin-1 col uk-width-2-3">
                                <input type="text" className="form-control  uk-input" name="firstname" id="first_name" onChange={e => updateFormData(e)} required placeholder="First Name" />
                            </div>
                            {/* <div className="uk-margin-1 col uk-width-2-3">
                                <input type="text" className="form-control  uk-input" name="lastname" id="last_name" onChange={e => updateFormData(e)} required placeholder="Last Name" />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="uk-margin-1 col uk-width-2-3">
                                <input type="text" className="form-control  uk-input" name="username" id="username" onChange={e => updateFormData(e)} required placeholder="Username" />
                            </div>
                            <div className="uk-margin-1 col uk-width-2-3">
                                <input type="email" className="form-control  uk-input" name="email" id="email" onChange={e => updateFormData(e)} required placeholder="Email" />
                            </div>
                        </div>
                        <div className="row">

                            {/* <div className="uk-margin-1 col uk-width-2-3">
                                <input id="bitcoin_wallet" type="text" className="form-control uk-input" name="wallet" onChange={e => updateFormData(e)} placeholder="Bitcoin Wallet(optional)" />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="uk-margin-1 col uk-width-2-3">
                                <input className="form-control  uk-input" id="password" type="password" name="password1" onChange={e => updateFormData(e)} placeholder="Password" />
                            </div>
                            <div className="uk-margin-1 col uk-width-2-3">
                                <input className="form-control  uk-input" id="confirm_password" type="password" name="password" onChange={e => updateFormData(e)} placeholder="Comfirm Password" />
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="uk-margin-1 col uk-width-1-5">
                                <input className="form-control  uk-input" maxLength={4} id="pin" type="password" name="pin" onChange={e => updateFormData(e)} placeholder="Transaction Pin" />
                            </div> */}
                            <div className="uk-margin-1 col uk-width-1-5">
                                <button className="uk-button uk-button-primary " type="button" id="sign_up_submit_btn" onClick={Register} >Signup</button>
                                <div id="msgSubmit" className="h3 text-center hidden" />
                                <div className="clearfix" />
                            </div>
                        </div>
                    </form>
                </div>
            </main>
            {/* all js here */}
            {/* jquery latest version */}
            {/* bootstrap js */}
            {/* Form validator js */}
            <footer>
                <div className="uk-container uk-light">
                    <div className="uk-grid uk-margin-large-top">
                        {/* <div className="uk-width-1-4@l uk-width-1-5@m uk-width-1-3@s">
                            <ul className="uk-list">
                                <li><a href="index.php">Home</a></li>
                                <li><a href="about.php">About Us</a></li>
                                <li><a href="contact.php">Contact Us</a></li>
                                <li><a href="faq.php">FAQ</a></li>
                                <li><a href="nfp.php">NFP</a></li>
                                <li><a href="fomc.php">FOMC</a></li>
                            </ul>
                        </div>
                        <div className="uk-width-1-4@l uk-width-1-5@m uk-width-1-3@s">
                            <ul className="uk-list">
                                <li><a href="rules.php">Rules</a></li>
                                <li><a href="for_marketers">For Marketers</a></li>
                                <li><a href="affiliate.php">Marketing</a></li>
                                <li><a href="https://adviserinfo.sec.gov/firm/summary/" target="_blank">FINRA</a></li>
                            </ul>
                        </div>
                        <div className="uk-width-1-4@l uk-width-2-5@m uk-width-1-1@s">
                            <div className="uk-align-right idz-footer-adjust">
                                <a href="#" className="uk-icon-button uk-margin-small-right" data-uk-icon="icon: facebook" />
                                <a href="#" className="uk-icon-button  uk-margin-small-right" data-uk-icon="icon: twitter" />
                                <a href="#" className="uk-icon-button  uk-margin-small-right" data-uk-icon="icon: instagram" />
                                <a href="#" className="uk-icon-button" data-uk-icon="icon: youtube" />
                            </div>
                            <div className="uk-align-right pr-5">
                                <a href="docs/Solidwavelength%20Comodo1.pdf" target="_blank"><img width={100} src="img/css.png" /></a>
                            </div>
                        </div> */}
                        <div className="uk-width-1-1 uk-margin-large-top uk-margin-large-bottom">
                            {/* logo footer begin */}
                            <div id="footer-logo">

                                <p><span>Copyright ©2020 Solidwavelength Investment Company Inc. All Rights Reserved.</span></p>
                            </div>
                            {/* logo footer end */}
                            <hr />
                            <div className="uk-margin-small-top">
                                <p> <p>Solidwavelength was founded in 2016 by a
                                    group of professional forex and stock traders which has helped individual investors,
                                    their families, and institutions reach their financial goals by listening to their
                                    needs, offering solutions, and providing objective guidance along the way. Learn more
                                    about how we can help you.</p></p>
                            </div>

                            <div>
                                <div style={{ float: 'left' }}>
                                    <img src="/img/sipc-logo.svg" width={150} alt="logo" />
                                </div>
                                <div style={{ float: 'right' }}>
                                    <p>@2020  Solidwavelength Investment Management Company</p>
                                    <p>The Salisbury Boutique Hotel, 43/45 Salisbury Road, Edinburgh,<br /> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="uk-inline" href="#" data-uk-totop data-uk-scroll data-uk-scrollspy="cls: uk-animation-fade; hidden: true; offset-top: 100px; repeat: true" />
            </footer>    {/* Javascript */}
            {/* JS, Popper.js, and jQuery */}
            {/* news slider */}
            {/* register  Mon, 15 Mar 2021 23:53:27 GMT */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default Register;




{/* <div className="uk-margin-1 col uk-width-2-3">
<select className="form-control  uk-input" id="country" required value type="text" placeholder="Country">
    <option value="Afghanistan">Afghanistan</option>
    <option value="Albania">Albania</option>
    <option value="Algeria">Algeria</option>
    <option value="American Samoa">American Samoa</option>
    <option value="Andorra">Andorra</option>
    <option value="Angola">Angola</option>
    <option value="Anguilla">Anguilla</option>
    <option value="Antartica">Antarctica</option>
    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
    <option value="Argentina">Argentina</option>
    <option value="Armenia">Armenia</option>
    <option value="Aruba">Aruba</option>
    <option value="Australia">Australia</option>
    <option value="Austria">Austria</option>
    <option value="Azerbaijan">Azerbaijan</option>
    <option value="Bahamas">Bahamas</option>
    <option value="Bahrain">Bahrain</option>
    <option value="Bangladesh">Bangladesh</option>
    <option value="Barbados">Barbados</option>
    <option value="Belarus">Belarus</option>
    <option value="Belgium">Belgium</option>
    <option value="Belize">Belize</option>
    <option value="Benin">Benin</option>
    <option value="Bermuda">Bermuda</option>
    <option value="Bhutan">Bhutan</option>
    <option value="Bolivia">Bolivia</option>
    <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
    <option value="Botswana">Botswana</option>
    <option value="Bouvet Island">Bouvet Island</option>
    <option value="Brazil">Brazil</option>
    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
    <option value="Brunei Darussalam">Brunei Darussalam</option>
    <option value="Bulgaria">Bulgaria</option>
    <option value="Burkina Faso">Burkina Faso</option>
    <option value="Burundi">Burundi</option>
    <option value="Cambodia">Cambodia</option>
    <option value="Cameroon">Cameroon</option>
    <option value="Canada">Canada</option>
    <option value="Cape Verde">Cape Verde</option>
    <option value="Cayman Islands">Cayman Islands</option>
    <option value="Central African Republic">Central African Republic</option>
    <option value="Chad">Chad</option>
    <option value="Chile">Chile</option>
    <option value="China">China</option>
    <option value="Christmas Island">Christmas Island</option>
    <option value="Cocos Islands">Cocos (Keeling) Islands</option>
    <option value="Colombia">Colombia</option>
    <option value="Comoros">Comoros</option>
    <option value="Congo">Congo</option>
    <option value="Congo">Congo, the Democratic Republic of the</option>
    <option value="Cook Islands">Cook Islands</option>
    <option value="Costa Rica">Costa Rica</option>
    <option value="Cota D'Ivoire">Cote d'Ivoire</option>
    <option value="Croatia">Croatia (Hrvatska)</option>
    <option value="Cuba">Cuba</option>
    <option value="Cyprus">Cyprus</option>
    <option value="Czech Republic">Czech Republic</option>
    <option value="Denmark">Denmark</option>
    <option value="Djibouti">Djibouti</option>
    <option value="Dominica">Dominica</option>
    <option value="Dominican Republic">Dominican Republic</option>
    <option value="East Timor">East Timor</option>
    <option value="Ecuador">Ecuador</option>
    <option value="Egypt">Egypt</option>
    <option value="El Salvador">El Salvador</option>
    <option value="Equatorial Guinea">Equatorial Guinea</option>
    <option value="Eritrea">Eritrea</option>
    <option value="Estonia">Estonia</option>
    <option value="Ethiopia">Ethiopia</option>
    <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
    <option value="Faroe Islands">Faroe Islands</option>
    <option value="Fiji">Fiji</option>
    <option value="Finland">Finland</option>
    <option value="France">France</option>
    <option value="France Metropolitan">France, Metropolitan</option>
    <option value="French Guiana">French Guiana</option>
    <option value="French Polynesia">French Polynesia</option>
    <option value="French Southern Territories">French Southern Territories</option>
    <option value="Gabon">Gabon</option>
    <option value="Gambia">Gambia</option>
    <option value="Georgia">Georgia</option>
    <option value="Germany">Germany</option>
    <option value="Ghana">Ghana</option>
    <option value="Gibraltar">Gibraltar</option>
    <option value="Greece">Greece</option>
    <option value="Greenland">Greenland</option>
    <option value="Grenada">Grenada</option>
    <option value="Guadeloupe">Guadeloupe</option>
    <option value="Guam">Guam</option>
    <option value="Guatemala">Guatemala</option>
    <option value="Guinea">Guinea</option>
    <option value="Guinea-Bissau">Guinea-Bissau</option>
    <option value="Guyana">Guyana</option>
    <option value="Haiti">Haiti</option>
    <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
    <option value="Holy See">Holy See (Vatican City State)</option>
    <option value="Honduras">Honduras</option>
    <option value="Hong Kong">Hong Kong</option>
    <option value="Hungary">Hungary</option>
    <option value="Iceland">Iceland</option>
    <option value="India">India</option>
    <option value="Indonesia">Indonesia</option>
    <option value="Iran">Iran (Islamic Republic of)</option>
    <option value="Iraq">Iraq</option>
    <option value="Ireland">Ireland</option>
    <option value="Israel">Israel</option>
    <option value="Italy">Italy</option>
    <option value="Jamaica">Jamaica</option>
    <option value="Japan">Japan</option>
    <option value="Jordan">Jordan</option>
    <option value="Kazakhstan">Kazakhstan</option>
    <option value="Kenya">Kenya</option>
    <option value="Kiribati">Kiribati</option>
    <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
    <option value="Korea">Korea, Republic of</option>
    <option value="Kuwait">Kuwait</option>
    <option value="Kyrgyzstan">Kyrgyzstan</option>
    <option value="Lao">Lao People's Democratic Republic</option>
    <option value="Latvia">Latvia</option>
    <option value="Lebanon">Lebanon</option>
    <option value="Lesotho">Lesotho</option>
    <option value="Liberia">Liberia</option>
    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
    <option value="Liechtenstein">Liechtenstein</option>
    <option value="Lithuania">Lithuania</option>
    <option value="Luxembourg">Luxembourg</option>
    <option value="Macau">Macau</option>
    <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
    <option value="Madagascar">Madagascar</option>
    <option value="Malawi">Malawi</option>
    <option value="Malaysia">Malaysia</option>
    <option value="Maldives">Maldives</option>
    <option value="Mali">Mali</option>
    <option value="Malta">Malta</option>
    <option value="Marshall Islands">Marshall Islands</option>
    <option value="Martinique">Martinique</option>
    <option value="Mauritania">Mauritania</option>
    <option value="Mauritius">Mauritius</option>
    <option value="Mayotte">Mayotte</option>
    <option value="Mexico">Mexico</option>
    <option value="Micronesia">Micronesia, Federated States of</option>
    <option value="Moldova">Moldova, Republic of</option>
    <option value="Monaco">Monaco</option>
    <option value="Mongolia">Mongolia</option>
    <option value="Montserrat">Montserrat</option>
    <option value="Morocco">Morocco</option>
    <option value="Mozambique">Mozambique</option>
    <option value="Myanmar">Myanmar</option>
    <option value="Namibia">Namibia</option>
    <option value="Nauru">Nauru</option>
    <option value="Nepal">Nepal</option>
    <option value="Netherlands">Netherlands</option>
    <option value="Netherlands Antilles">Netherlands Antilles</option>
    <option value="New Caledonia">New Caledonia</option>
    <option value="New Zealand">New Zealand</option>
    <option value="Nicaragua">Nicaragua</option>
    <option value="Niger">Niger</option>
    <option value="Nigeria">Nigeria</option>
    <option value="Niue">Niue</option>
    <option value="Norfolk Island">Norfolk Island</option>
    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
    <option value="Norway">Norway</option>
    <option value="Oman">Oman</option>
    <option value="Pakistan">Pakistan</option>
    <option value="Palau">Palau</option>
    <option value="Panama">Panama</option>
    <option value="Papua New Guinea">Papua New Guinea</option>
    <option value="Paraguay">Paraguay</option>
    <option value="Peru">Peru</option>
    <option value="Philippines">Philippines</option>
    <option value="Pitcairn">Pitcairn</option>
    <option value="Poland">Poland</option>
    <option value="Portugal">Portugal</option>
    <option value="Puerto Rico">Puerto Rico</option>
    <option value="Qatar">Qatar</option>
    <option value="Reunion">Reunion</option>
    <option value="Romania">Romania</option>
    <option value="Russia">Russian Federation</option>
    <option value="Rwanda">Rwanda</option>
    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
    <option value="Saint LUCIA">Saint LUCIA</option>
    <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
    <option value="Samoa">Samoa</option>
    <option value="San Marino">San Marino</option>
    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
    <option value="Saudi Arabia">Saudi Arabia</option>
    <option value="Senegal">Senegal</option>
    <option value="Seychelles">Seychelles</option>
    <option value="Sierra">Sierra Leone</option>
    <option value="Singapore">Singapore</option>
    <option value="Slovakia">Slovakia (Slovak Republic)</option>
    <option value="Slovenia">Slovenia</option>
    <option value="Solomon Islands">Solomon Islands</option>
    <option value="Somalia">Somalia</option>
    <option value="South Africa">South Africa</option>
    <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
    <option value="Span">Spain</option>
    <option value="SriLanka">Sri Lanka</option>
    <option value="St. Helena">St. Helena</option>
    <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
    <option value="Sudan">Sudan</option>
    <option value="Suriname">Suriname</option>
    <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
    <option value="Swaziland">Swaziland</option>
    <option value="Sweden">Sweden</option>
    <option value="Switzerland">Switzerland</option>
    <option value="Syria">Syrian Arab Republic</option>
    <option value="Taiwan">Taiwan, Province of China</option>
    <option value="Tajikistan">Tajikistan</option>
    <option value="Tanzania">Tanzania, United Republic of</option>
    <option value="Thailand">Thailand</option>
    <option value="Togo">Togo</option>
    <option value="Tokelau">Tokelau</option>
    <option value="Tonga">Tonga</option>
    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
    <option value="Tunisia">Tunisia</option>
    <option value="Turkey">Turkey</option>
    <option value="Turkmenistan">Turkmenistan</option>
    <option value="Turks and Caicos">Turks and Caicos Islands</option>
    <option value="Tuvalu">Tuvalu</option>
    <option value="Uganda">Uganda</option>
    <option value="Ukraine">Ukraine</option>
    <option value="United Arab Emirates">United Arab Emirates</option>
    <option value="United Kingdom" selected>United Kingdom</option>
    <option value="United States">United States</option>
    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
    <option value="Uruguay">Uruguay</option>
    <option value="Uzbekistan">Uzbekistan</option>
    <option value="Vanuatu">Vanuatu</option>
    <option value="Venezuela">Venezuela</option>
    <option value="Vietnam">Viet Nam</option>
    <option value="Virgin Islands (British)">Virgin Islands (British)</option>
    <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
    <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
    <option value="Western Sahara">Western Sahara</option>
    <option value="Yemen">Yemen</option>
    <option value="Yugoslavia">Yugoslavia</option>
    <option value="Zambia">Zambia</option>
    <option value="Zimbabwe">Zimbabwe</option>;
</select>
</div> */}