import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ConfirmInvest from "./ConfirmInvest";
import Swal from 'sweetalert2'

function Investments() {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    var today = new Date(); //Today's Date

    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);

    const [amountToInvest, setamountToInvest] = useState("0")
    const [investmentReady, setinvestmentReady] = useState(false)

    const [loading, setloading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)

        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    navigate("/login");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .onSnapshot((function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloading(false)
                } else {
                    console.log("No such document!");
                }
            })
            )
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };





    const confirmAndProceed = () => {
        setinvestmentReady(true)


    }

    const [image, setimage] = useState(null);
    const [url, seturl] = useState("");
    const [progress, setprogress] = useState(0);
    const [imagepresnt, setimagepresent] = useState(false);
    const [prevfile, setprevFile] = useState("")


    const handleChange = (e) => {
        if (e.target.files[0]) {
            setimage(e.target.files[0]);
            setimagepresent(true);
            setprevFile(URL.createObjectURL(e.target.files[0]))
        }
    };

    const handleUpload = async () => {
        if (amountToInvest != 0) {
            if (amountToInvest >= parseInt(selectedinvestment.min) && amountToInvest <= parseInt(selectedinvestment.max)) {

                Swal.fire({
                    title: 'Are you sure that the deposit have been made?',
                    text: "Your investment won't activate if your deposit is not confimed",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, i have made the deposit'
                }).then((result) => {
                    if (result.isConfirmed) {

                        const uploadTask = storage.ref(`images/${image.name}`).put(image);
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const progress = Math.round(
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                );
                                setprogress(progress);
                                setOpen(!open);
                            },
                            (error) => {
                                console.log(error);
                                Swal.fire({
                                    icon: error,
                                    title: 'Oops...',
                                    text: "Go back to investment and select a plan",
                                })
                                setOpen(!open);

                            },
                            () => {
                                storage
                                    .ref("images")
                                    .child(image.name)
                                    .getDownloadURL()
                                    .then((url) => {
                                        seturl(url);
                                        updatehistory(url);
                                        setprogress(0);
                                        setimagepresent(false)
                                        // alert("we will we credit your balance shortly");

                                        setimage(null);
                                    });
                            }
                        );
                    }
                })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Select An Amount Within The Selected Investment Range",
                })
            }


        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Select An Amount",
            })
        }

        //check wether amt is empty or file is not selected

    };


    const updatehistory = async (urls) => {
        if (selectedinvestment.name == "" || selectedinvestment.name == null) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Go back to investment and select a plan",
            })
            return
        }

        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = await db.collection("users").doc(userids);
        await washingtonRef.update({
            Deposithistory: firebase.firestore.FieldValue.arrayUnion({
                date: new Date().getTime(),
                amt: amountToInvest,
                name: selectedinvestment.name,
                min: selectedinvestment.min,
                max: selectedinvestment.max,
                duration: selectedinvestment.duration,
                profit: selectedinvestment.profit,
                paymentmethod: selectedinvestment.paymentmethod,
                description: selectedinvestment.description,
                image: urls,
            }),
        });

        setOpen(false);
        Swal.fire({
            icon: 'success',
            title: 'Investment was sucessfull',
            text: 'We will confirm your deposit and activate your account',
            // footer: '<a href="">Why do I have this issue?</a>'
        })
    }

    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const navigate = useNavigate();
    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/login");
    };

    return (
        <>
            {
                investmentReady == false && (
                    <div>
                        <div className="nk-app-root">
                            <div className="nk-wrap ">
                                <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                                    <div className="container-xl wide-lg">
                                        <div className="nk-header-wrap">
                                            <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                            <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                            <div className="nk-header-menu" data-content="headerNav">
                                                <div className="nk-header-mobile">
                                                    <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                                    <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                                </div>
                                                <ul className="nk-menu nk-menu-main">
                                                    <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>

                                                    <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Invest</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Payouts</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                    <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                                    <li className="nk-menu-item active has-sub">
                                                        {/* <a href="#" className="nk-menu-link nk-menu-toggle"><span className="nk-menu-text">Pages</span></a>
                                                        <ul className="nk-menu-sub">
                                                            <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                                            <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                                            <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                            <div id="google_translate_element" />
                                                        </ul> */}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="nk-header-tools">
                                                <ul className="nk-quick-nav">
                                                    <li className="dropdown notification-dropdown">
                                                        <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                            <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right 2">
                                                            <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                            <div className="dropdown-body">
                                                                <div className="nk-notification">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                <th className="tb-col-time">
                                                                                    <span className="overline-title">Activity</span>
                                                                                </th>
                                                                                <th className="tb-col-ip">
                                                                                    <span className="overline-title">Details</span>
                                                                                </th>
                                                                            </tr>
                                                                        </thead><thead>
                                                                            <tr>
                                                                                <td className="tb-col-os">Authentication</td>
                                                                                <td className="tb-col-ip">
                                                                                    <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                                </td>
                                                                            </tr><tr>
                                                                            </tr></thead></table>
                                                                </div>
                                                                <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                            </div>
                                                        </div></li>
                                                    <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                                    <li className="dropdown user-dropdown order-sm-first">
                                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                            <div className="user-toggle">
                                                                <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                                <div className="user-info d-none d-xl-block">
                                                                    <div className="user-status user-status-verified">verified</div>
                                                                    <div className="user-name dropdown-indicator">{userdetails.firstname}  {userdetails.lastname} </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                                <div className="user-card">
                                                                    <div className="user-avatar"><span>OS</span></div>
                                                                    <div className="user-info"><span className="lead-text">{userdetails.firstname} </span><span className="sub-text">{userdetails.email} </span></div>
                                                                    <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                                </div>
                                                            </div>
                                                            <div className="dropdown-inner user-account-info">
                                                                <h6 className="overline-title-alt">Account Balance</h6>
                                                                <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                                <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                            </div>
                                                            <div className="dropdown-inner">
                                                                {/* <ul className="link-list">
                                                                    <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                                    <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                                    <li><Link to="/login"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                                </ul> */}
                                                            </div>
                                                            <div className="dropdown-inner">
                                                                <ul className="link-list">
                                                                    <li><a onClick={showtopnav}><em className="icon ni ni-signout" /><span></span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-content nk-content-lg nk-content-fluid">
                                    <div className="container-xl wide-lg">
                                        <div className="nk-content-inner">
                                            <div className="nk-content-body">
                                                <div className="nk-block-head nk-block-head-lg">
                                                    <div className="nk-block-head-content">
                                                        <div className="nk-block-head-sub"><Link to="/investmentplans" className="back-to"><em className="icon ni ni-arrow-left" /><span>Back to plan</span></Link></div>
                                                        <div className="nk-block-head-content">
                                                            <h2 className="nk-block-title fw-normal">Ready to get started?</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-block invest-block">
                                                    <form action="#" className="invest-form">
                                                        <div className="row g-gs">
                                                            <div className="col-lg-7">
                                                                <div className="invest-field form-group">
                                                                    <input type="hidden" defaultValue="silver" name="iv-plan" id="invest-choose-plan" />
                                                                    <div className="dropdown invest-cc-dropdown">
                                                                        <a href="#" className="invest-cc-choosen dropdown-indicator" data-toggle="dropdown">
                                                                            <div className="coin-item">
                                                                                <div className="coin-icon"><em className="icon ni ni-offer-fill" /></div>
                                                                                <div className="coin-info"><span className="coin-name" id="coin__name_$">{selectedinvestment.name}</span><span className="coin-text" id="coin_name__label__val">{selectedinvestment.description}</span></div>
                                                                            </div>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                                                                            <ul className="invest-cc-list">
                                                                                <li className="invest-cc-item selected">
                                                                                    <a className="invest-cc-opt" data-plan="silver">
                                                                                        <div className="coin-item">
                                                                                            <div className="coin-icon"><em className="icon ni ni-offer-fill" /></div>
                                                                                            <div className="coin-info"><span className="coin-name">Rookie Plan</span><span className="coin-text">Get profit 15% daily</span></div>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="invest-cc-item selected">
                                                                                    <a className="invest-cc-opt" data-plan="starter">
                                                                                        <div className="coin-item">
                                                                                            <div className="coin-icon"><em className="icon ni ni-offer-fill" /></div>
                                                                                            <div className="coin-info"><span className="coin-name">Professional Plan</span><span className="coin-text">Get profit 45% weekly</span></div>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="invest-cc-item">
                                                                                    <a className="invest-cc-opt" data-plan="dimond">
                                                                                        <div className="coin-item">
                                                                                            <div className="coin-icon"><em className="icon ni ni-offer-fill" /></div>
                                                                                            <div className="coin-info"><span className="coin-name">Megagoldtrades Plan</span><span className="coin-text">Get profit 50% weekly</span></div>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                                {/*                                                 
                                                    <li class="invest-cc-item">
                                                       <a href="#" class="invest-cc-opt" data-plan="dimond">
                                                          <div class="coin-item">
                                                             <div class="coin-icon"><em class="icon ni ni-offer-fill"></em></div>
                                                             <div class="coin-info"><span class="coin-name">Platinum Plan</span><span class="coin-text">Invest for 7 days and get profit 25%</span></div>
                                                          </div>
                                                       </a>
                                                    </li> */}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="invest-field form-group">
                                                                    <div className="form-label-group"><label className="form-label">Choose Quick Amount to Invest</label></div>
                                                                    <div className="invest-amount-group g-2">
                                                                        <div className="invest-amount-item" onclick="selectAmount('500')"><input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-1" onChange={() => { setamountToInvest(500) }} /><label className="invest-amount-label" htmlFor="iv-amount-1">$ 500</label></div>
                                                                        <div className="invest-amount-item" onclick="selectAmount('1000')"><input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-2" onChange={() => { setamountToInvest(1000) }} /><label className="invest-amount-label" htmlFor="iv-amount-2">$ 1000</label></div>
                                                                        <div className="invest-amount-item" onclick="selectAmount('1500')"><input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-3" onChange={() => { setamountToInvest(1500) }} /><label className="invest-amount-label" htmlFor="iv-amount-3">$ 1500</label></div>
                                                                        <div className="invest-amount-item" onclick="selectAmount('2000')"><input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-4" onChange={() => { setamountToInvest(2000) }} /><label className="invest-amount-label" htmlFor="iv-amount-4">$ 2,000</label></div>
                                                                        <div className="invest-amount-item" onclick="selectAmount('2500')"><input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-5" onChange={() => { setamountToInvest(2500) }} /><label className="invest-amount-label" htmlFor="iv-amount-5">$ 2,500</label></div>
                                                                        <div className="invest-amount-item" onclick="selectAmount('3000')"><input type="radio" className="invest-amount-control" name="iv-amount" id="iv-amount-6" onChange={() => { setamountToInvest(3000) }} /><label className="invest-amount-label" htmlFor="iv-amount-6">$ 3,000</label></div>
                                                                    </div>
                                                                </div>
                                                                <div className="invest-field form-group">
                                                                    <div className="form-label-group">
                                                                        <label className="form-label">Or Enter Your Amount</label>
                                                                        <div className="dropdown">
                                                                            {/* <a href="#" class="link py-1" data-toggle="dropdown">Change Currency</a> */}
                                                                            <div className="dropdown-menu dropdown-menu-xxs dropdown-menu-right">
                                                                                <ul className="link-list-plain sm text-center">
                                                                                    <li><a href="#">USD</a></li>
                                                                                    {/* <li><a href="#">EUR</a></li> */}
                                                                                    {/* <li><a href="#">CAD</a></li> */}
                                                                                    <li><a href="#">BTC</a></li>
                                                                                    <li><a href="#">ETH</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-control-group">
                                                                        <div className="form-info">USD</div>
                                                                        <input type="text" id="input_amount" className="form-control form-control-amount form-control-lg" min={selectedinvestment.min} max={selectedinvestment.max} onChange={(e) => { setamountToInvest(e.target.value) }} placeholder={0.00} />
                                                                        <div className="form-range-slider" id="amount-step" />
                                                                    </div>
                                                                    <div className="form-note pt-2" id="plan_notes">Note: Minimum invest of ${selectedinvestment.min} and Maximum of ${selectedinvestment.max}</div>
                                                                </div>
                                                                {/* <div className="invest-field form-group">
                                                                    <div className="form-label-group"><label className="form-label">Choose Payment Method</label></div>
                                                                    <input type="hidden" defaultValue="wallet" name="iv-wallet" id="invest-choose-wallet" />
                                                                    <div className="dropdown invest-cc-dropdown">
                                                                        <a href="#" className="invest-cc-choosen dropdown-indicator" data-toggle="dropdown">
                                                                            <div className="coin-item" id="payment_method_selected">
                                                                                <div className="coin-icon"><em className="icon ni ni-wallet-alt" /></div>
                                                                                <div className="coin-info"><span className="coin-name">Wallet</span><span className="coin-text">Current balance: $0.00</span></div>
                                                                            </div>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                                                                            <ul className="invest-cc-list">
                                                                                <li className="invest-cc-item selected" id="wallet__">
                                                                                    <a href="#wallet__" onclick="selectPaymentMethod('wallet',0.00)" className="invest-cc-opt" data-plan="silver">
                                                                                        <div className="coin-item">
                                                                                            <div className="coin-icon"><em className="icon ni ni-wallet-alt" /></div>
                                                                                            <div className="coin-info"><span className="coin-name">Wallet</span><span className="coin-text">Current balance: $0.00</span></div>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="invest-cc-item" id="bth__">
                                                                                    <a href="#bth__" onclick="selectPaymentMethod('BTC')" className="invest-cc-opt" data-plan="starter">
                                                                                        <div className="coin-item">
                                                                                            <div className="coin-icon"><em className="icon ni ni-sign-btc" /></div>
                                                                                            <div className="coin-info"><span className="coin-name">BTC</span><span className="coin-text" /></div>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="invest-cc-item" id="eth__">
                                                                                    <a href="#eth__" onclick="selectPaymentMethod('ETH')" className="invest-cc-opt" data-plan="dimond">
                                                                                        <div className="coin-item">
                                                                                            <div className="coin-icon"><em className="icon ni ni-sign-eth" /></div>
                                                                                            <div className="coin-info"><span className="coin-name">ETH</span><span className="coin-text" /></div>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="invest-cc-item" id="eth__">
                                                                                    <div id="paypal-button" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="invest-field form-group">
                                                                    <div className="custom-control custom-control-xs custom-checkbox"><input type="checkbox" className="custom-control-input" id="checkbox" />
                                                                        <label className="custom-control-label" htmlFor="checkbox">I agree to the <a href="/rules.php" target="_blank">terms and &amp; conditions.</a></label></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-5 offset-xl-1">
                                                                <div className="card card-bordered ml-lg-4 ml-xl-0">
                                                                    <div className="nk-iv-wg4">
                                                                        <div className="nk-iv-wg4-sub">
                                                                            <h6 className="nk-iv-wg4-title title">Your Investment Details</h6>
                                                                            <ul className="nk-iv-wg4-overview g-2">
                                                                                <li>
                                                                                    <div className="sub-text">Name of scheme</div>
                                                                                    <div className="lead-text" id="name_of__plam">{selectedinvestment.name}</div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="sub-text">Term of the scheme</div>
                                                                                    <div className="lead-text" id="investment_term">{selectedinvestment.duration} days</div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="sub-text">profit %</div>
                                                                                    <div className="lead-text" id="profit_percent">{selectedinvestment.profit} %</div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="sub-text">Total Return</div>
                                                                                    <div className="lead-text" id="total_return">$  {(((parseFloat(selectedinvestment.profit) * amountToInvest) / 100) * parseFloat(selectedinvestment.duration) + parseFloat(amountToInvest))}</div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="sub-text">Term start at</div>
                                                                                    <div className="lead-text">Today</div>
                                                                                </li>
                                                                                {/* <li>
                                                       <div class="sub-text">Term end at</div>
                                                       <div class="lead-text">12 - 25 - 2020</div>
                                                    </li> */}
                                                                            </ul>
                                                                        </div>
                                                                        <div className="nk-iv-wg4-sub">
                                                                            <ul className="nk-iv-wg4-list">
                                                                                <li>
                                                                                    <div className="sub-text">Payment Method</div>
                                                                                    <div className="lead-text" id="payment_method">{selectedinvestment.paymentmethod}</div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="nk-iv-wg4-sub">
                                                                            <ul className="nk-iv-wg4-list">
                                                                                <li>
                                                                                    <div className="sub-text">Amount to invest</div>
                                                                                    <div className="lead-text" id="amount_to_invest">$ {amountToInvest}</div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="sub-text">Conversion Fee <span>(0.0%)</span></div>
                                                                                    <div className="lead-text">$ 0.0</div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="nk-iv-wg4-sub">
                                                                            <ul className="nk-iv-wg4-list">
                                                                                <li>
                                                                                    <div className="lead-text">Total Charge</div>
                                                                                    <div className="caption-text text-primary" id="total_charge">$ {amountToInvest}</div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="nk-iv-wg4-sub text-center bg-lighter">
                                                                            <div id="error_alert" className="text-danger" />
                                                                            <div id="success_alert" className="text-success" />
                                                                            <input type="button" onClick={confirmAndProceed} id="investment_init" className="btn btn-lg btn-primary ttu" defaultValue="Confirm & proceed" />
                                                                            <a onClick={confirmAndProceed} href="#" className="btn btn-lg btn-primary ttu d-none" id="wallet_plan_alert" data-toggle="modal" data-target="#invest-plan">Confirm &amp; proceed</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-footer nk-footer-fluid bg-lighter">
                                    <div className="container-xl wide-lg">
                                        <div className="nk-footer-wrap">
                                            <div className="nk-footer-copyright"> © 2020 <a href="#">Megagoldtrades Investment Management Company</a></div>
                                            <div className="nk-footer-links">
                                                <ul className="nav nav-sm">
                                                    {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                               <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                               <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" tabIndex={-1} id="invest-plan">
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body modal-body-md text-center">
                                        <div className="nk-modal">
                                            <h4 className="nk-modal-title">Confirm Your Payment</h4>
                                            <h6 className="text-danger" id="error_response_modal_transact" />
                                            <h6 className="text-success" id="success_response_modal_transact" />
                                            <div className="nk-modal-text">
                                                <p>To confirm your payment of <strong id="modal_payment_amount">$</strong> on this order #93033939 using your <strong>Wallet</strong>. Please enter your Wallet Pin code in order complete the payment or cancel.</p>
                                            </div>
                                            <div className="nk-modal-form">
                                                <div className="form-group"><input type="password" id="transaction_pin" className="form-control form-control-password-big text-center" /></div>
                                            </div>
                                            <div className="nk-modal-action">
                                                <a href="#" className="btn btn-lg btn-mw btn-primary" id="confirm_payment_modal">Confirm Payment</a>
                                                <a href="#" data-toggle="modal" data-target="#confirm-invest" className="btn btn-lg btn-mw btn-primary d-none" id="confirm_payment_modal">Confirm Payment</a>
                                                <div className="sub-text sub-text-alt mt-3 mb-4">This transaction will appear on your wallet statement as Invest * <span id="modal_wallet_payment_plan" />.</div>
                                                <a href="#" className="link link-soft" data-dismiss="modal">Cancel and return</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" tabIndex={-1} id="confirm-invest">
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <a href="#" className="close" data-dismiss="modal"><em className="icon ni ni-cross-sm" /></a>
                                    <div className="modal-body modal-body-lg text-center">
                                        <div className="nk-modal">
                                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success" />
                                            <h4 className="nk-modal-title">Successfully sent payment!</h4>
                                            <div className="nk-modal-text">
                                                <p className="sub-text">You have successfully order the Investment Plan of ‘Rookie’ with amount of <strong>$250.00</strong> using your <strong>NioWallet</strong>.</p>
                                            </div>
                                            <div className="nk-modal-action-lg">
                                                <ul className="btn-group flex-wrap justify-center g-4">
                                                    <li><a href="invest.php" className="btn btn-lg btn-mw btn-primary">More Invest</a></li>
                                                    <li><a href="scheme-details.php?id=" className="btn btn-lg btn-mw btn-dim btn-primary"><em className="icon ni ni-reports" /><span>See the plan</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer bg-lighter">
                                        <div className="text-center w-100">
                                            <p>Earn upto $25 for each friend your refer! <a href="#">Invite friends</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="nk-sticky-toolbar">
                            <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
                        </ul>
                        <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                            <div className="nk-demo-head">
                                <h6 className="mb-0">Preview Settings</h6>
                                <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                            </div>
                            <div className="nk-opt-panel" data-simplebar>
                                <div className="nk-opt-set">
                                    <div className="nk-opt-set-title">Main UI Style</div>
                                    <div className="nk-opt-list col-2x">
                                        <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                                        <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                                        <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                                        <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                                    </div>
                                </div>
                                <div className="nk-opt-set nk-opt-set-header">
                                    <div className="nk-opt-set-title">Header Style</div>
                                    <div className="nk-opt-list col-4x">
                                        <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                                        <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                                        <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                                        <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                                    </div>
                                    <div className="nk-opt-set-title">Header Option</div>
                                    <div className="nk-opt-list col-2x">
                                        <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                                        <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                                    </div>
                                </div>
                                <div className="nk-opt-set nk-opt-set-skin">
                                    <div className="nk-opt-set-title">Primary Skin</div>
                                    <div className="nk-opt-list">
                                        <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                                        <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                                        <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                                        <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                                        <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                                        <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                                    </div>
                                </div>
                                <div className="nk-opt-set">
                                    <div className="nk-opt-set-title">Skin Mode</div>
                                    <div className="nk-opt-list col-2x">
                                        <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                                        <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                                    </div>
                                </div>
                                <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* imagepresnt
    prevfile
    progress
    url
    handleUpload
    handleChange */}
            {
                investmentReady && (
                    <ConfirmInvest
                        name={selectedinvestment.description}
                        capitalInvseted={amountToInvest}
                        paymentMethod={selectedinvestment.paymentmethod}
                        totalReturn={((parseFloat(selectedinvestment.profit) * amountToInvest) / 100) * parseFloat(selectedinvestment.duration)}
                        Term={selectedinvestment.duration}
                        orderedDate={today}
                        termEnds={today.setDate(today.getDate() + parseFloat(selectedinvestment.duration))}
                        imagepresnt={imagepresnt}
                        prevfile={prevfile}
                        progress={progress}
                        url={url}
                        handleUpload={handleUpload}
                        handleChange={handleChange}
                    />
                )
            }

            {/* */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Investments