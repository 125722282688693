import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ConfirmInvest from "./ConfirmInvest";
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";
function Withdrawal() {
    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const [amount, setamount] = useState("")
    const [coin, setcoin] = useState("")
    const [wallet, setwallet] = useState("")
    const [AtmNumber, setAtmNumber] = useState("")


    function hideStringPart(inputString, start, end) {
        if (start < 0 || end > inputString.length || start >= end) {
          console.error('Invalid start and end positions.');
          return inputString;
        }
      
        const maskedPart = '*'.repeat(end - start);
        const maskedString = inputString.slice(0, start) + maskedPart + inputString.slice(end);
        return maskedString;
      }

    const updateUserBalance = async (bal) => {
        const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = await db.collection("users").doc(userids);
        washingtonRef
            .update({
                balance: newEarings,
            })
            .then(function () {
                console.log("Document successfully up2dated!");
                // alert("withdrawal was successful we will get back to you");
                Swal.fire({
                    icon: 'success',
                    title: 'withdrawal was successful we will get back to you!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata();
    };

    const updatewithdrawalhistory = async () => {

        if (wallet == "" || coin == "" || amount == "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Select wallet, coin , and amount to procecced withdrawal",
                footer: '<a href="">Why do I have this issue?</a>'
            })
            return 0
        }
        // console.log(cointype)
        const newBal = parseFloat(userdetails.balance); /*- parseFloat(amount);*/
        const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
                amt: amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending"

            }),
        });
        await SendMailtoClient()
        await updateUserBalance()
    };

    const SendMailtoClient = async () => {
        var templateParams = {
            to_name: userdetails.username,
            message:
                ` <div style="margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none;
               mso-line-height-rule: exactly; font-family: arial,'helvetica neue', helvetica, sans-serif; line-height: 21px; color: #333333;
               font-size: 14px;">we wish to inform you that the following transaction occured on your account
                  <br ></br>
                  <br ></br>
                  Transaction Type: Debit
                  <br ></br>
                  <br ></br>
                  Amount:$${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  <br ></br>
                  <br ></br>
                  Description: Fund Withdrawal BY ${userdetails.username}
                  <br ></br>
                  ${coin}:(${wallet})
                  <br ></br>
                  <br ></br>
                  Transaction Status:Pending
              </div>`,

            user_email: userdetails.email,
            // date: moment().format("MMM Do YY")
        };

        setloading(false)
        //handleClick1()

        //main smtp
        //service_tii01ob
        //template_6snt1nl
        //VrhXxKVP73UrXlBRe

        //main gmail 
        //service_426azip
        //template_6snt1nl
        //VrhXxKVP73UrXlBRe

        //golds100  smtp
        // "service_elz5gqo",
        // "template_wwic9nf",
        // templateParams,
        // "mXji13wQlSp8KJ0BX"

        //golds100 gmail
        // "service_qk78cze",
        // "template_wwic9nf",
        // templateParams,
        // "mXji13wQlSp8KJ0BX"
        emailjs
            .send(
                "service_426azip",
                "template_6snt1nl",
                templateParams,
                "VrhXxKVP73UrXlBRe"
            )
            .then(
                function (response) {
                    setloading(false)


                },
                function (err) {
                    setloading(false)
                    alert("FAILED...", err);
                    console.log(err)
                }
            );
    }

    const confirmWitdrawal = async () => {
        setOpen(true);
        if (amount != "") {
            if (parseFloat(userdetails.balance) < amount) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "balance not enough",
                    footer: '<a href="">Why do I have this issue?</a>'
                })
            } else {
                if (AtmNumber != userdetails.ATM_CARD_DETAILS.cardNumber  ) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Credit/Debit Card Number is incorrect ",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                    setOpen(false);
                } else {
                    if (userdetails.kycstate == false ) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: " kyc is not yet verified",
                            footer: '<a href="/kyc">Click here to verify</a>'
                        })
                        setOpen(false);
                    } else {
                        await updatewithdrawalhistory()
                        setOpen(false);
                    }
                }
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "no amount selected",
                footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    }

    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")
    }

    const navigate = useNavigate();

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/login");
    };

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)

        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    // setloading(false)
                    navigate("/login");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .onSnapshot((function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloading(false)
                } else {
                    console.log("No such document!");
                }
            })
            )
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    return (
        <div>
            {/* <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
            <meta charSet="utf-8" />
            <meta name="author" content="Megagoldtrades Investment Management Company" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="description" content="A powerful and conceptual apps base dashboard template that especially build for developers and programmers." />
            <link rel="shortcut icon" href="../images/favicon.png" />
            <title>Funds Withdrawal Application </title>
            <link rel="stylesheet" href="../assets/css/dashlite8162.css?ver=1.9.0" />
            <link id="skin-default" rel="stylesheet" href="../assets/css/theme8162.css?ver=1.9.0" /> */}
            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu" data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>

                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Invest</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Payouts</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                        {/* <li className="nk-menu-item active has-sub">
                                            <a href="#" className="nk-menu-link nk-menu-toggle"><span className="nk-menu-text">Pages</span></a>
                                            <ul className="nk-menu-sub">
                                                <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                <div id="google_translate_element" />
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        <div className="user-name dropdown-indicator">{userdetails.firstname}  {userdetails.lastname} </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        <div className="user-avatar"><span>OS</span></div>
                                                        <div className="user-info"><span className="lead-text">{userdetails.firstname} </span><span className="sub-text">{userdetails.email} </span></div>
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                </div>
                                                <div className="dropdown-inner">
                                                    {/* <ul className="link-list">
                                                        <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                        <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                        <li><Link to="/login"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                    </ul> */}
                                                </div>
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><a onClick={showtopnav}><em className="icon ni ni-signout" /><span></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="kyc-app wide-sm m-auto">
                                        <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                                            <div className="nk-block-head-content text-center">
                                                <h2 className="nk-block-title fw-normal">Funds Withdrawal</h2>
                                                <div className="nk-block-des">
                                                    <p>You have a total of USD 0 in your wallet available for withdrawal. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-block">
                                            <div className="card card-bordered">
                                                <div className="nk-kycfm">
                                                    <div className="nk-kycfm-head">
                                                        <div className="nk-kycfm-count">01</div>
                                                        <div className="nk-kycfm-title">
                                                            <h5 className="title">Personal Details</h5>
                                                            <p className="sub-title">Your simple personal information required for
                                                                identification</p>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-content">
                                                        <div className="nk-kycfm-note"><em className="icon ni ni-info-fill" data-toggle="tooltip" data-placement="right" title="Tooltip on right" />
                                                            <p>Please type carefully and fill out the form with your personal
                                                                details.</p>
                                                        </div>
                                                        <div className="row g-4">
                                                            {/* <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">First Name <span className="text-danger">*</span></label></div>
                                                                    <div className="form-control-group"><input type="text" className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Last
                                                                        Name <span className="text-danger">*</span></label>
                                                                    </div>
                                                                    <div className="form-control-group"><input type="text" className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Email Address <span className="text-danger">*</span></label></div>
                                                                    <div className="form-control-group"><input type="text" className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Username <span className="text-danger">*</span></label></div>
                                                                    <div className="form-control-group"><input type="text" className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-head">
                                                        <div className="nk-kycfm-count">02</div>
                                                        <div className="nk-kycfm-title">
                                                            <h5 className="title">Your Withdrawal Informaton</h5>
                                                            <p className="sub-title">details about your withdrawal.</p>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-content">
                                                        <div className="nk-kycfm-note"><em className="icon ni ni-info-fill" data-toggle="tooltip" data-placement="right" title="Tooltip on right" />
                                                            <p>Your can’t edit these details once you submitted the form.</p>
                                                        </div>
                                                        <div className="row g-4">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Amount <span className="text-danger">*</span></label></div>
                                                                    <div className="form-control-group"><input id="amount" type="text" onChange={(e) => { setamount(e.target.value) }} className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Wallet Address <span className="text-danger">*</span> </label></div>
                                                                    <div className="form-control-group"><input id="wallet_address" type="text" onChange={(e) => { setwallet(e.target.value) }} className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-head">
                                                        <div className="nk-kycfm-count">03</div>
                                                        <div className="nk-kycfm-title">
                                                            <h5 className="title">Crypto Wallet Type</h5>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-content">
                                                        <ul className="nk-kycfm-control-list g-3">
                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { setcoin("Btc") }} type="radio" name="id-proof" id="bitcoin" data-title="Bitcoin" defaultChecked /><label className="nk-kycfm-label" htmlFor="bitcoin"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Bitcoin</span></label></li>
                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { setcoin("Usdt ") }} type="radio" name="id-proof" id="eth" data-title="eth" /><label className="nk-kycfm-label" htmlFor="eth"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Tether Usdt
                                                                </span></label></li>
                                                        </ul>
                                                        <h6 className="title">To avoid delays when withdrawing funds, Please make
                                                            sure you check below:</h6>
                                                        <ul className="list list-sm list-checked">
                                                            <li>You have the available funds.</li>
                                                            <li>Wallet Address provided are accurate</li>
                                                            <li>Personal Details match current profile data on your account</li>
                                                        </ul>
                                                    </div>

                                                    {
                                                        loading == false && (
                                                            <div>
                                                                <main id="app">

                                                                    <aside className="card-front">
                                                                        <label className="number" htmlFor="cardNumber">
                                                                         CVV  {userdetails.ATM_CARD_DETAILS.cvv}
                                                                        </label>
                                                                        <div class="card--back">
                                                                                <div class="card__strip"></div>
                                                                                <div class="card__signature"></div>
                                                                                {/* <div class="card__ccv">CVV {userdetails.ATM_CARD_DETAILS.cvv}</div> */}
                                                                            </div>
                                                                    </aside>

                                                                </main>
                                                            </div>
                                                        )
                                                    }




                                                    {
                                                        loading == false && (
                                                            <div>
                                                                <main id="app">

                                                                    <aside className="card-front">
                                                                        <label className="number" htmlFor="cardNumber">
                                                                      
                                                                            {hideStringPart(userdetails.ATM_CARD_DETAILS.cardNumber, 4, 12)}
                                                                        </label>
                                                                        <label className="name" htmlFor="cardHolder">
                                                                            {userdetails.firstname}  {userdetails.lastname}
                                                                        </label>
                                                                        <label className="expiry" htmlFor="expiryMonth">
                                                                            {userdetails.ATM_CARD_DETAILS.expirationDate}
                                                                        </label>
                                                                        <img className="cardLogo" data-v-5d206127 data-v-8fcb32d4 style={{ opacity: 1 }} src="https://megagoldtrades.com/images/pax.png" />
                                                                        <div className="chip">
                                                                            <svg role="img" viewBox="0 0 100 100" aria-label="Chip">
                                                                                <use href="#chip-lines" />
                                                                            </svg>
                                                                        </div>
                                                                        <svg className="contactless" role="img" viewBox="0 0 24 24" aria-label="Contactless">
                                                                            <use href="#contactless">
                                                                            </use></svg>
                                                                    </aside>

                                                                    <svg id="chip">
                                                                        <g id="chip-lines">
                                                                            <polyline points="0,50 35,50" />
                                                                            <polyline points="0,20 20,20 35,35" />
                                                                            <polyline points="50,0 50,35" />
                                                                            <polyline points="65,35 80,20 100,20" />
                                                                            <polyline points="100,50 65,50" />
                                                                            <polyline points="35,35 65,35 65,65 35,65 35,35" />
                                                                            <polyline points="0,80 20,80 35,65" />
                                                                            <polyline points="50,100 50,65" />
                                                                            <polyline points="65,65 80,80 100,80" />
                                                                        </g>
                                                                    </svg>
                                                                </main>



                                                            </div>
                                                        )
                                                    }



                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="form-label-group"><label className="form-label">INSERT ATM TOKEN CARD NUMBER <span className="text-danger">*</span> </label></div>
                                                            <div className="form-control-group"><input id="wallet_address" type="text"
                                                                onChange={(e) => { setAtmNumber(e.target.value) }} className="form-control form-control-lg"
                                                            /></div>
                                                        </div>
                                                    </div>























                                                    <div className="nk-kycfm-footer">
                                                        <div className="form-group">
                                                            <div className="custom-control custom-control-xs custom-checkbox"><input type="checkbox" className="custom-control-input" id="tc-agree" /><label className="custom-control-label" htmlFor="tc-agree">I Have Read The <a href="/terms-and-conditions.php" target="_blank">Terms Of
                                                                Condition</a> And <a href="/privacy-and-policy.php" target="_blank">Privacy Policy</a></label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-control-xs custom-checkbox"><input type="checkbox" className="custom-control-input" id="info-assure" /><label className="custom-control-label" htmlFor="info-assure">All The Personal Information I Have
                                                                Entered Is Correct.</label></div>
                                                        </div>
                                                        <div className="text-danger mt-2 mb-2" id="error-container">
                                                            <div className="text-success" id="success-container" />
                                                        </div>
                                                        <div className="nk-kycfm-action pt-2"><button onClick={confirmWitdrawal} type="button" id="withdraw" className="btn btn-lg btn-primary">Apply</button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020 <a href="#">Megagoldtrades Investment Management Company</a></div>
                            </div>
                            <div className="nk-footer-links">
                                <ul className="nav nav-sm">
                                    {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6><a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Withdrawal