import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyBAxpzWFT9lTxijWhdsSCIA24dXnm34kBs",
  authDomain: "megagoldtrades.firebaseapp.com",
  databaseURL: "https://megagoldtrades-default-rtdb.firebaseio.com",
  projectId: "megagoldtrades",
  storageBucket: "megagoldtrades.appspot.com",
  messagingSenderId: "328774219094",
  appId: "1:328774219094:web:20bd6c97f37c0347cad2f7"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()