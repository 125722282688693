import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

function ConfirmInvest(props) {
    const [walletAddress, setwalletAddress] = useState("")

    useEffect(() => {
        if (props.paymentMethod == "Btc") {
            setwalletAddress("bc1qfwe8undhfqavrlpccu7h0my4asr8qdv2agallr")
        } else if (props.paymentMethod == "Usdt") {
            setwalletAddress("TUEcstQkCQYiScopY4mqaebgW8uznVpgYB")
        }


    }, [])

    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }


    return (
        <div>
            <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
            <meta charSet="utf-8" />
            <meta name="author" content="Paxmint Investment Management Company" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="description" content="A powerful and conceptual apps base dashboard template that especially build for developers and programmers." />
            <link rel="shortcut icon" href="../images/favicon.png" />
            <title>Scheme / Plan Detail - Invest</title>
            <link rel="stylesheet" href="../assets/css/dashlite8162.css?ver=1.9.0" />
            <link id="skin-default" rel="stylesheet" href="../assets/css/theme8162.css?ver=1.9.0" />
            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu" data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>

                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Invest</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Payouts</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                        {/* <li className="nk-menu-item active has-sub">
                                            <a href="#" className="nk-menu-link nk-menu-toggle"><span className="nk-menu-text">Pages</span></a>
                                            <ul className="nk-menu-sub">
                                                <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                <div id="google_translate_element" />
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 22nd of April 2022 10:02:32 PM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm"><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        <div className="user-name dropdown-indicator">Osita Stephen</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        <div className="user-avatar"><span>OS</span></div>
                                                        <div className="user-info"><span className="lead-text">Ositasteve</span><span className="sub-text">ositaositas@yahoo.com</span></div>
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <a href="./withdrawal-form.php" className="link"><span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></a>
                                                </div>
                                                {/* <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                        <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                        <li><Link to="/login"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                    </ul>
                                                </div> */}
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><a href="../../api/crypto/users/logout.php"><em className="icon ni ni-signout" /><span>Sign out</span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub"><a href="/dashboard" className="text-soft back-to"><em className="icon ni ni-arrow-left"> </em><span>My Investment</span></a></div>
                                            <div className="nk-block-between-md g-4">
                                                <div className="nk-block-head-content">
                                                    <h2 className="nk-block-title fw-normal">{props.name} </h2>
                                                    <div className="nk-block-des">
                                                        <p>
                                                            <span className="badge badge-outline badge-warning">Awaiting confirmation</span></p>
                                                    </div>
                                                </div>
                                                <div className="nk-block-head-content">
                                                    <ul className="nk-block-tools gx-3">
                                                        {/* <li className="order-md-last"><a href="/api/crypto/cancel-deposit.php?id=165066503162632647003f2" className="btn btn-danger"><em className="icon ni ni-cross" /> <span>Cancel this plan</span> </a></li> */}
                                                        <li><Link to="/invetmentplans" className="btn btn-icon btn-light"><em className="icon ni ni-reload" /></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="card card-bordered">
                                            <div className="card-inner">
                                                <div className="row gy-gs">
                                                    <div className="col-12">
                                                        <div className="bg-success text-white p-3">
                                                            Please pay the sum of<b> ${props.capitalInvseted} </b>{props.paymentMethod == "Btc" && <>Bitcoin</>}  {props.paymentMethod == "Eth" && <>Ethereum</>}equivalent as of today into the wallet address below
                                                            and await transaction confirmation, we would let you know when this investment starts through
                                                            your valid email.
                                                            <br />
                                                            <br />
                                                            <div className="nk-refwg-url">
                                                                <div className="form-control-wrap">
                                                                    <div className="form-clip clipboard-init" data-clipboard-target="#refUrl" data-success="Copied" data-text="Copy Address"><em className="clipboard-icon icon ni ni-copy" /> <span className="clipboard-text">Copy Link</span></div>
                                                                    <div className="form-icon"><em className="icon ni ni-link-alt" /></div>
                                                                    <input type="text" className="form-control copy-text" id="refUrl" defaultValue={walletAddress} />
                                                                </div>
                                                            </div>

                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="fileUpload"
                                                                    onChange={props.handleChange}
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="fileUpload"
                                                                >
                                                                    upload proof of payment
                                                                </label>
                                                            </div>



                                                            {props.imagepresnt && (
                                                                <img src={props.prevfile} className="previmg" />
                                                            )}

                                                            {props.imagepresnt && (
                                                                <>

                                                                    {/* <div className="nk-opt-reset" onClick={props.handleUpload}><a className="reset-opt-setting">upload</a></div> */}
                                                                    <a onClick={props.handleUpload} className="btn btn-lg btn-primary " >Click To Confirm Deposit</a>

                                                                </>
                                                            )}

                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="nk-iv-wg3">
                                                            <div className="nk-iv-wg3-group flex-lg-nowrap gx-4">
                                                                <div className="nk-iv-wg3-sub">
                                                                    <div className="nk-iv-wg3-amount">
                                                                        <div className="number">USD {props.capitalInvseted}</div>
                                                                    </div>
                                                                    <div className="nk-iv-wg3-subtitle">Invested Amount</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 offset-lg-2">
                                                        <div className="nk-iv-wg3 pl-md-3">
                                                            <div className="nk-iv-wg3-group flex-lg-nowrap gx-4">
                                                                <div className="nk-iv-wg3-sub">
                                                                    <div className="nk-iv-wg3-amount">
                                                                        <div className="number">USD {props.totalReturn}</div>
                                                                    </div>
                                                                    <div className="nk-iv-wg3-subtitle">Potential Return</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="schemeDetails" className="nk-iv-scheme-details">
                                                <ul className="nk-iv-wg3-list">
                                                    <li>
                                                        <div className="sub-text">Term</div>
                                                        <div className="lead-text">{props.term} Days</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Term start at</div>
                                                        <div className="lead-text">Has not started</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Term Ends at</div>
                                                        <div className="lead-text">{props.termEnds.toString()}</div>
                                                    </li>
                                                </ul>
                                                <ul className="nk-iv-wg3-list">
                                                    <li>
                                                        <div className="sub-text">Ordered date</div>
                                                        <div className="lead-text">{props.orderedDate.toString()}</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Approved date</div>
                                                        <div className="lead-text">Not Approved</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Payment method</div>
                                                        <div className="lead-text">{props.paymentMethod}</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Paid <small>(fee include)</small></div>
                                                        <div className="lead-text"><span className="currency currency-usd">USD</span> {props.capitalInvseted}</div>
                                                    </li>
                                                </ul>
                                                <ul className="nk-iv-wg3-list">
                                                    <li>
                                                        <div className="sub-text">Capital invested</div>
                                                        <div className="lead-text"><span className="currency currency-usd">USD</span> {props.capitalInvseted}</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Weekly income</div>
                                                        <div className="lead-text"><span className="currency currency-usd">USD</span> {((15 * props.capitalInvseted) / 100) * 7} </div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Net profit</div>
                                                        <div className="lead-text"><span className="currency currency-usd">USD</span> {props.capitalInvseted}</div>
                                                    </li>
                                                    <li>
                                                        <div className="sub-text">Total return</div>
                                                        <div className="lead-text"><span className="currency currency-usd">USD</span> {props.totalReturn}</div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020 <a href="#">Paxmint Investment Management Company</a></div>
                                <div className="nk-footer-links">
                                    <ul className="nav nav-sm">
                                        {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                       <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                       <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6>
                    <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmInvest