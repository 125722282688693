import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ConfirmInvest from "./ConfirmInvest";
import Swal from 'sweetalert2'

function Profile() {

    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);
    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")
    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const navigate = useNavigate();
    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/login");
    };

    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)

        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    navigate("/login");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .onSnapshot((function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloading(false)
                } else {
                    console.log("No such document!");
                }
            })
            )
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    return (
        <div>
            <div className="nk-app-root">
                <div className="nk-wrap">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none " onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu" data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>

                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Invest</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Payouts</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                        <li className="nk-menu-item active has-sub">
                                            <a href="#" className="nk-menu-link nk-menu-toggle"><span className="nk-menu-text">Pages</span></a>
                                            {/* <ul className="nk-menu-sub">
                                                <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                <div id="google_translate_element" />
                                            </ul> */}
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        <div className="user-name dropdown-indicator">{userdetails.firstname}  {userdetails.lastname} </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        <div className="user-avatar"><span>OS</span></div>
                                                        <div className="user-info"><span className="lead-text">{userdetails.firstname} </span><span className="sub-text">{userdetails.email} </span></div>
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                </div>
                                                <div className="dropdown-inner">
                                                    {/* <ul className="link-list">
                                                        <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                        <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                        <li><Link to="/login"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                    </ul> */}
                                                </div>
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><a onClick={showtopnav}><em className="icon ni ni-signout" /><span></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub"><span>My Profile</span></div>
                                            <h2 className="nk-block-title fw-normal">Account Info</h2>
                                            <div className="nk-block-des">
                                                <p>
                                                    You have full control to manage your own account
                                                    setting.
                                                    <span className="text-primary"><em className="icon ni ni-info" /></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="nk-nav nav nav-tabs">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/profile">Personal</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/settings">Security<span className="d-none s-sm-inline">
                                                Setting</span></Link>
                                        </li>
                                        {/* <li class="nav-item">
                    <a class="nav-link" href="profile-notify.php"
                      >Notifications</a
                    >
                  </li> */}
                                        {/* <li class="nav-item">
                    <a class="nav-link" href="profile-connected.html"
                      >Connect Social</a
                    >
                  </li> */}
                                    </ul>
                                    <div className="nk-block">
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                                <h5 className="nk-block-title">Personal Information</h5>
                                                <div className="nk-block-des">
                                                    <p>
                                                        Basic info, like your name and address, that you use
                                                        on Megagoldtrades Investment Management Company Platform.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-bordered">
                                            <div className="nk-data data-list">
                                                <div className="data-item">
                                                    <div className="data-col">
                                                        <span className="data-label">Profile Picture</span><span className="data-value">
                                                            <img style={{ borderRadius: '50%' }} height={100} width={100} src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" />
                                                        </span>
                                                    </div>
                                                    <form action="/api/crypto/users/profilePhoto.php" method="post" encType="multipart/form-data">
                                                        <input type="file" onchange="clickSubmit()" hidden name="upload" id="upload" /><br />
                                                        <input type="submit" id="profilePhoto" hidden name="submit" defaultValue="Upload" />
                                                    </form>
                                                    <div className="data-col data-col-end" onclick="clickFileInput()">
                                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                                    </div>
                                                </div>
                                                <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                                    <div className="data-col">
                                                        <span className="data-label">Full Name</span><span className="data-value">{userdetails.firstname}  {userdetails.lastname} </span>
                                                    </div>
                                                    <div className="data-col data-col-end">
                                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                                    </div>
                                                </div>
                                                <div className="data-item">
                                                    <div className="data-col">
                                                        <span className="data-label">Email</span><span className="data-value">{userdetails.email} </span>
                                                    </div>
                                                    <div className="data-col data-col-end">
                                                        <span className="data-more disable"><em className="icon ni ni-lock-alt" /></span>
                                                    </div>
                                                </div>
                                                <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                                    <div className="data-col">
                                                        <span className="data-label">Phone Number</span><span className="data-value text-soft">Not add yet</span>
                                                    </div>
                                                    <div className="data-col data-col-end">
                                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                                    </div>
                                                </div>
                                                <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                                    <div className="data-col">
                                                        <span className="data-label">Bitcoin Address</span><span className="data-value">Bnanbbsbbs</span>
                                                    </div>
                                                    <div className="data-col data-col-end">
                                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                                    </div>
                                                </div>
                                                <div className="data-item" data-toggle="modal" data-target="#profile-edit" data-tab-target="#address">
                                                    <div className="data-col">
                                                        <span className="data-label">Address</span><span className="data-value"><br />, </span>
                                                    </div>
                                                    <div className="data-col data-col-end">
                                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright">
                                    © 2020  by <a href="#">Megagoldtrades Investment Management Company</a>
                                </div>
                                <div className="nk-footer-links">
                                    <ul className="nav nav-sm">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Terms</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Privacy</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Help</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* in show style={{ display: "block" }} */}
            <div className="modal fade " tabIndex={-1} role="dialog" id="profile-edit">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <a href="#" className="close" data-dismiss="modal"><em className="icon ni ni-cross-sm" /></a>
                        <div className="modal-body modal-body-lg">
                            <h5 className="title">Update Profile</h5>
                            <ul className="nk-nav nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#personal">Personal</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#address">Address</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="personal">
                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="firstname">First Name</label><input type="text" className="form-control form-control-lg" id="firstname" defaultValue="Osita" placeholder="Enter First name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="display-name">Display Name</label><input type="text" className="form-control form-control-lg" id="display-name" disabled placeholder="{userdetails.firstname} " />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="phone-no">Phone Number</label><input type="text" className="form-control form-control-lg" id="phone-no" defaultValue placeholder="Phone Number" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="lastname">Last Name</label><input type="text" className="form-control form-control-lg" id="lastname" defaultValue="Stephen" placeholder="Enter your last name" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="latest-sale" /><label className="custom-control-label" htmlFor="latest-sale">Use full name to display
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="text-danger w-100 d-block" id="errorContainer2" />
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <button type="button" id="update_personal" className="btn btn-lg btn-primary">Update Profile</button>
                                                </li>
                                                <li>
                                                    <a href="#" data-dismiss="modal" className="link link-light">Cancel</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="address">
                                    <div className="row gy-4">
                                        <div className="bg-primary text-white w-100 text-center">All Fields are required to save</div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="address-l1">Address Line 1</label><input type="text" className="form-control form-control-lg" id="address-l1" defaultValue />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="address-l2">Address Line 2</label><input type="text" className="form-control form-control-lg" id="address-l2" defaultValue />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="address-st">State</label><input type="text" className="form-control form-control-lg" id="address-st" defaultValue />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="address-county">Country</label><select aria-selected className="form-select" id="address-county" data-ui="lg"><option value="Canada">Canada</option><option value="United States">United State</option><option value="United Kingdom">United Kingdom</option><option value="Australie">Australia</option><option value="India">India</option><option>Bangladesh</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <div className="text-danger" id="errorContainer">
                                                </div>
                                                <li>
                                                    <button type="button" id="update_address" className="btn btn-lg btn-primary">Update Address</button>
                                                </li>
                                                <li>
                                                    <a href="#" data-dismiss="modal" className="link link-light">Cancel</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings">
                    <a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a>
                </li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6>
                    <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default">
                                <span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span>
                            </div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean">
                                <span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span>
                            </div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady">
                                <span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span>
                            </div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy">
                                <span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span>
                            </div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light">
                                <span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span>
                            </div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default">
                                <span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span>
                            </div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark">
                                <span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span>
                            </div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme">
                                <span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span>
                            </div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular">
                                <span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span>
                            </div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed">
                                <span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span>
                            </div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default">
                                <span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span>
                            </div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue">
                                <span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span>
                            </div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian">
                                <span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span>
                            </div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple">
                                <span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span>
                            </div>
                            <div className="nk-opt-item" data-key="skin" data-update="green">
                                <span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span>
                            </div>
                            <div className="nk-opt-item" data-key="skin" data-update="red">
                                <span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span>
                            </div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light">
                                <span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span>
                            </div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark">
                                <span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span>
                            </div>
                        </div>
                    </div>
                    <div className="nk-opt-reset">
                        <a href="#" className="reset-opt-setting">Reset Setting</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile