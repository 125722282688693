import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { f, database, storage, auth } from "./config";
import firebase from "firebase";
import db from "./config";
import { GlobalContext } from "./Globalstate";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Forgetpassword() {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const [{ userdetails, loggedin }, dispatch] = useContext(GlobalContext);


    const [open1, setOpen1] = React.useState(false);
    const [errormess, seterrormess] = useState("")


    const handleClick1 = () => {
        setOpen1(true);
    };
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen1(false);
    };

    const [formData, setFormData] = useState({

        email: "",
        password: ""
    });

    const { fullname, email, password } = formData;

    const updateFormData = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        console.log(formData)
    }

    const chagepasswordemail = () => {


        if (email != "") {
            setOpen(true)
            var actionCodeSettings = {
                url:
                    "https://megagoldtrades.com//login/?email=" +
                    email,
            };
            auth.sendPasswordResetEmail(email, actionCodeSettings).then(function () {
                // handleClick3() 
                setOpen(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Password reset was sent to your mail',
                    text: 'check your mail to reset password',

                })
            }).catch(function (error) {
                alert(error)
            });
        } else {
            console.log("field is empty")
            alert("input correct email address")
        }
    };

    return (
        <body onload="startTime()">
            <header>


                <div class="uk-container">
                    <div class="uk-grid">
                        <div class="uk-width-2-5@l uk-width-1-3@m uk-width-1-4@s">

                            <div id="header-logo">
                                <a class="uk-logo" href="index_php.html"><img src="/images/pax.png" alt="stockpile" /></a>
                            </div>

                        </div>
                        <div class="uk-width-3-5@l uk-width-2-3@m uk-width-3-4@s uk-visible@m">
                            <div class="uk-grid">
                                <div class="uk-width-1-2 pr-3">
                                    <div class="idz-mini-nav uk-align-right">
                                        <div id="google_translate_element"></div>

                                    </div>
                                </div>
                                <div class="uk-width-1-2 phone-number">
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <hr />

                            <nav class="uk-navbar-container uk-visible@m" data-uk-navbar style={{ Zindex: "980" }} data-uk-sticky="animation: uk-animation-slide-top; cls-active: uk-sticky; cls-inactive: uk-navbar-container; bottom: #offset">
                                <div class="uk-navbar-left">
                                    <ul class="uk-navbar-nav">
                                        <li class="uk-active"> <a href="index_php.html">Home</a></li>
                                        <li> <a href="#">Company</a>
                                            <div class="uk-navbar-dropdown uk-navbar-dropdown-width-2">
                                                <div class="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                                                    <div>
                                                        <ul class="uk-nav uk-navbar-dropdown-nav">
                                                            <li><a href="about.html">About Us</a></li>
                                                            <li><a href="affiliate.html">Marketing</a></li>
                                                            <li><a href="index.html">For Marketers</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="cannabis.html">Cannabis</a></li>
                                        <li><a href="faq.html">FAQ</a></li>
                                        <li><a href="contact.html">Contact Us</a></li>

                                    </ul>
                                </div>
                                <div class="uk-navbar-right">
                                    <div data-uk-margin>
                                        <Link class="uk-button uk-button-primary" to="/login">Get Started</Link>
                                    </div>
                                </div>
                            </nav>

                            <a class="uk-button uk-align-center idz-mobile-nav uk-hidden@m" href="#modal-full" data-uk-icon="icon: menu" data-uk-toggle>Menu</a>
                            <div id="modal-full" class="uk-modal-full" data-uk-modal>
                                <div class="uk-modal-dialog">
                                    <button class="uk-modal-close-full uk-close-large" type="button" data-uk-close></button>
                                    <div data-uk-height-viewport>
                                        <div class="uk-position-cover uk-overlay uk-overlay-primary uk-flex uk-flex-center uk-flex-middle">
                                            <ul class="uk-nav-primary uk-nav-parent-icon" data-uk-nav>
                                                <li class="uk-active uk-parent"> <a href="#">Home</a>
                                                    <ul class="uk-nav-sub">
                                                        <li><a href="index_php.html">Homepage 1</a></li>
                                                        <li><a href="index-layout2.html">Homepage 2</a></li>
                                                        <li><a href="index-layout3.html">Homepage 3</a></li>
                                                        <li><a href="index-layout4.html">Homepage 4</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="investment.html">Investment</a></li>
                                                <li><a href="education.html">Education</a></li>
                                                <li class="uk-parent"> <a href="#">Pages</a>
                                                    <ul class="uk-nav-sub">
                                                        <li><a href="about.html">About Us</a></li>
                                                        <li><a href="team.html">Our Team</a></li>
                                                        <li><a href="testimonials.html">Testimonials</a></li>
                                                        <li><a href="faq.html">FAQ</a></li>
                                                        <li><a href="sitemap.html">Sitemap</a></li>
                                                        <li><a href="404-error.html">404 Error</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="news.html">News</a></li>
                                                <li><a href="contact.html">Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div class="simple-marquee-container">
                    <div class="marquee-sibling">
                        INSIGHTS
                    </div>
                    <div class="marquee">
                        <ul class="marquee-content-items">
                            <li>The intelligent investor should recognize that market panics can create great prices for good companies and good prices for great companies </li>
                        </ul>
                    </div>
                </div>    </section>
            <main>
                <section id="pagetitle-container">
                    <div class="uk-container">
                        <div class="uk-grid">
                            <div class="uk-width-1-1">

                                <ul class="uk-breadcrumb uk-margin-top uk-float-right">
                                    <li><a href="index_php.html">Home</a></li>
                                    <li><a href="login.html">Login</a></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="container custom_cont-1">
                    <div class="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s mt-5">
                        <h4 class="area-title">Input your email for password reset</h4>
                        <p>Do not Disclose your email & password to any third party.</p>
                    </div>
                    <form method="POST" id="contactForm">
                        <div class="message" id="message"></div>
                        <input type="hidden" name="_token" />            <div class="uk-margin uk-width-2-3-1">
                            <input class="form-control  uk-input" id="login_email" name="email"
                                onChange={e => updateFormData(e)}
                                required data-error="Please enter your email" type="email" placeholder="Email" />
                        </div>

                        <div>
                            <button class="uk-button uk-button-primary " id="login_submit_btn" type="button" onClick={chagepasswordemail} >Change password</button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                        </div>
                    </form>
                </div>
            </main>

            <footer>
                <div class="uk-container uk-light">
                    <div class="uk-grid uk-margin-large-top">
                        {/* <div class="uk-width-1-4@l uk-width-1-5@m uk-width-1-3@s">
                            <ul class="uk-list">
                                <li><a href="index_php.html">Home</a></li>
                                <li><a href="about.html">About Us</a></li>
                                <li><a href="contact.html">Contact Us</a></li>
                                <li><a href="faq.html">FAQ</a></li>
                                <li><a href="nfp.html">NFP</a></li>
                                <li><a href="fomc.html">FOMC</a></li>
                            </ul>
                        </div>
                        <div class="uk-width-1-4@l uk-width-1-5@m uk-width-1-3@s">
                            <ul class="uk-list">

                                <li><a href="index.html">For Marketers</a></li>
                                <li><a href="affiliate.html">Marketing</a></li>
                                <li><a href="https://adviserinfo.sec.gov/firm/summary/" target="_blank">FINRA</a></li>
                            </ul>
                        </div>
                        <div class="uk-width-1-4@l uk-width-2-5@m uk-width-1-1@s">
                            <div class="uk-align-right idz-footer-adjust">
                                <a href="#" class="uk-icon-button uk-margin-small-right" data-uk-icon="icon: facebook"></a>
                                <a href="#" class="uk-icon-button  uk-margin-small-right" data-uk-icon="icon: twitter"></a>
                                <a href="#" class="uk-icon-button  uk-margin-small-right" data-uk-icon="icon: instagram"></a>
                                <a href="#" class="uk-icon-button" data-uk-icon="icon: youtube"></a>
                            </div>
                            <div class='uk-align-right pr-5'>
                                <a href='docs/Solidwavelength%20Comodo1.pdf' target='_blank'><img width='100' src="img/css.png" /></a >
                            </div>
                        </div> */}
                        <div class="uk-width-1-1 uk-margin-large-top uk-margin-large-bottom">

                            <div id="footer-logo">

                                <p><span>Copyright ©2020 Solidwavelength Investment Company Inc. All Rights Reserved.</span></p>
                            </div>

                            <hr />
                            <div class="uk-margin-small-top">
                                <p> <p>Solidwavelength was founded in 2016 by a
                                    group of professional forex and stock traders which has helped individual investors,
                                    their families, and institutions reach their financial goals by listening to their
                                    needs, offering solutions, and providing objective guidance along the way. Learn more
                                    about how we can help you.</p></p>
                            </div>

                            <div>
                                <div style={{ float: "left" }}>
                                    <img src="/img/sipc-logo.svg" width="150" alt="logo" />
                                </div>
                                <div style={{ float: "right" }}>
                                    <p>@2020  Solidwavelength Investment Management Company</p>
                                    <p>270 NORTH LOOP 1604 EAST, SAN ANTONIO<br /> </p >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="uk-inline" href="#" data-uk-totop data-uk-scroll data-uk-scrollspy="cls: uk-animation-fade; hidden: true; offset-top: 100px; repeat: true"></a>
            </footer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </body>
    );
}

export default Forgetpassword;
